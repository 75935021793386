<template>
  <div>
<!--导航-->
  <div><van-nav-bar
  left-arrow
  @click-left="Close"
  :fixed="true" :right-text="righttext"
  @click-right="onClickRight"
  >
  <div slot="title" style="
  border-radius: 50rem;
  background-color: rgba(241, 241, 241, 1);
  width: 152px;
  justify-content: center;
  align-items: center;display: flex">
  <div   :class="isShowgoods== true?'div-leftafter':'div-left'"  @click="ShowList(0)" >商品收藏</div>
  <div  :class="isShowgoods== false?'div-rightafter':'div-right'"   @click="ShowList(1)">店铺收藏</div>
  <!--<van-button round type="info" :color="isShowgoods== true?'#FF3C00':'#F1F1F1'" style="height:24px" @click="ShowList(0)">商品收藏</van-button>
  <van-button round type="info" :color="isShowgoods== false?'#FF3C00':'#F1F1F1'" style="height:24px"  @click="ShowList(1)">店铺收藏</van-button>
  " -->
   </div>
  </van-nav-bar>
  </div>
  <!--商品列表- 我的商品-->

     <div class="wrap">
       <div v-show="isShowgoods">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
       <van-list

      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
      :error.sync="error"
       error-text="请求失败，点击重新加载"
       style="padding-bottom:50px;"
       v-show="!isEmpty"
    >
        <div class="item"  v-for="(item,index) in goods"
        :key="index" 
        :name="item.id"  >

          <van-checkbox   v-show="isShow&&item.goodsSpu!=null"  v-model="item.isChecked"
           @click="OnClick(item,index)" style="margin-left:5px"  /> 
          <van-card  v-if="item.goodsSpu!=null"
          price="10.00" :thumb="item.goodsSpu!=null?(item.goodsSpu.picUrls.length>0?item.goodsSpu.picUrls[0]:''):''"
          @click="openGoods(item,index)"
          >
           <div slot="title">
          <span style="font-size:17px;margin-left:5px;"  class="goods-p1">{{item.goodsSpu.name!=null?item.goodsSpu.name:''}}</span>
  </div>
   <div slot="price"> 
         
  </div>
   <div slot="desc">
       <span class="word4-collect" style="display:block">¥{{item.goodsSpu.priceDown!=null?item.goodsSpu.priceDown:'0'}}</span>
       <span class="info-collect">{{item.goodsSpu.spName!=null?item.goodsSpu.spName:''}}</span>
      <div><span class="info-collect">原价</span><span  class="info-collect" style="text-decoration:line-through">¥{{item.goodsSpu.priceUp}}</span>
      <span class="info-collect" style="  margin: 6px 0 0 7px;">已售{{item.goodsSpu.saleNum}}</span></div>
      <div><span class="span1-collect">  
       <span class="word10-collect">券</span>
       <span class="word10-collect">¥{{item.goodsSpu.couponMoney==""?0:item.goodsSpu.couponMoney}}</span></span>
       <span  style=" font-size: 10px; color: rgba(255, 0, 0, 1);background-color: rgba(254, 240, 240, 1); margin-left:2px">分享赚¥{{item.goodsSpu.shareMoney.toFixed(2)}}</span></div>
  </div>
        </van-card>
        </div>
           </van-list>
            </van-pull-refresh>
    </div>
    </div>
<!--店铺管理-->
       <div class="wrap" >
       <div v-show="!isShowgoods">
             <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
       <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
      :error.sync="error"
       error-text="请求失败，点击重新加载"
       style="padding-bottom:50px;"
       v-show="!isEmpty"
    >
    <div class="main1-collect"   v-for="(item,index) in shops" 
        :key="index"
        :name="item.id"  
       >
     <van-col :span="spanCount" >  
       <div> <van-checkbox  v-show="isShow"  v-model="item.isChecked" @click="OnClick(item,index)"  
        style="margin-top:80px;margin-left:10px"/> </div> 
      </van-col>
     <van-col :span="24-spanCount"  @click="openShop(item,index)" >
             <div style="margin-left:10px">
         <div>
          <van-col span="4">
             <img
                referrerpolicy="no-referrer" style="width:48px;height:48px;margin-top:10px"
                :src="item.shopInfo.imgUrl!=null?item.shopInfo.imgUrl:''"
              />
          </van-col>
          <van-col span="20">
            <span class="txt7-collect" style="    display: block">{{item.shopInfo.name!=null?item.shopInfo.name:''}}</span> 
            <span class="word19-collect">
              {{item.shopInfo.detail!=null?item.shopInfo.detail:''}}</span>
          </van-col>
        </div>
        <div class="divmain-collect" v-if="item.shopInfo.listGoodsSpu!=null&&item.shopInfo.listGoodsSpu.length>0">
          <div v-for="(i,index) in item.shopInfo.listGoodsSpu" :key="i" class="icon" >
            <div class="container-collect"> 
            <img  style="width:90px;height:90px" :src="item.shopInfo.listGoodsSpu[index].picUrls.length>0?
            item.shopInfo.listGoodsSpu[index].picUrls[0]:''"/>
          </div>
        </div>
        </div>
        </div>
     </van-col>
      </div>
      </van-list>
      </van-pull-refresh>
    </div>
    </div>
    

      <!---底部导航栏---->

<div v-show="isShow">
  <van-submit-bar
   button-text="删除" @submit="deleteGoods">
    <van-checkbox style="margin-right:50%;width:80px" @click="checkAll" v-model="AllChecked">全选</van-checkbox>
</van-submit-bar>

</div>
  </div>
</template>

<script>
import {getObjList,deleteObj} from '@/api/collect/collect'
import {Dialog, Toast } from "vant";
export default {
   components: {
  },
  data() {
    return {
      loading: false,
      finished: false,
      isShowgoods:true,
      righttext:'管理',
      isShow:false,
      page: 1,//请求第几页
      pageSize: 10,//每页请求的数量
      total: 0,//总共的数据条数
      page1: 1,//请求第几页
      pageSize1: 10,//每页请求的数量
      total1: 0,//总共的数据条数
      goods: [],
      shops:[],
      session:null,
      selectedData:[],//选中的商品
      AllChecked:'',//全选
      checked:[],
      checkedGoodsid:[],
      type:1,
      spanCount:0,
      flexcontent: [
        ],
      error: false,
      refreshing:false,
      isEmpty: false
    };
    
  },

  computed: {
  },
mounted()
{
  //获取app传递过来的session
 
  if(this.$route.query.session!=null&&this.$route.query.session!='')
  {
    this.$store.commit('setSession', this.$route.query.session)
  }
  //获取app传递过来的token
   if(this.$route.query.token!=null&&this.$route.query.token!='')
  {
    this.$store.commit('setToken', this.$route.query.token)
  }
    //empower
   if(this.$route.query.empower!=null&&this.$route.query.empower!='')
  {
    this.$store.commit('setEmpower', this.$route.query.empower)
  }
  //else{
   // this.$store.commit('setSession', '52df66b2-7bc6-482a-a17c-3f4cd1222649')
  //}

   //this.isShowgoods=this.$store.state.collectType
  this.ShowList(this.$store.state.collectType)
  //this.getObjShopList()
},
  methods: {
    onClickRight()
    {
      this.isShow=this.righttext=='完成'?false:true;
      if(this.isShow)
      {
        this.spanCount=4
      }else{
        this.spanCount=0
      }
      this.righttext=this.righttext=='完成'?'管理':'完成';
    },
    
    //商品管理  店铺管理
    ShowList(num)
    {
      this.page = 1;
       if(num==0)
       {
         this.selectedData=[]
         this.type=1
         this.goods=[]
         this.checkedGoodsid=[]
         //显示商品
         this.isShowgoods=true;
         this.getObjList(this.type)
       }else{
         this.type=2
         this.shops=[]
         this.checkedGoodsid=[]
         this.selectedData=[]
         this.isShowgoods=false;
         this.getShopList(this.type)
       }
      this.$store.commit('setCollectType', num)
    },
    //上滑加载
      onLoad() {
      
      if(this.type==1)
      {  this.page++;
      this.getObjList(this.type);
     }
      else{   this.page1++;
        this.getShopList(this.type)
     }
    },
       onRefresh() {
      // 清空列表数据
     
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      if(this.type==1)
      {   this.page = 1;
      this.getObjList(this.type);
     }
      else{    this.page1 = 1;
        this.getShopList(this.type)
     }
    },
    //获取商品列表
    getObjList(type)
    {
      //type:收藏了类型 1 商品 2 商铺
        let params ={'type':type,'current':this.page,'size':this.pageSize}
        getObjList(params).then((res) => {
        if (res.data.code === 0) {
           
          this.total = res.data.data.total;

          if (this.refreshing) {
            this.goods = [];
            this.refreshing = false;
          }
           this.goods = this.goods.concat(res.data.data.records);
           this.goods.forEach(m => {
           this.$set(m, 'isChecked', false);
           });
          this.loading = false;
           //alert(JSON.stringify( this.goods))
         if (res.data.data.records == null || res.data.data.records.length === 0) {
          // 加载结束
           this.finished = true;
           return;
         }
       
          if (this.goods.length >= this.total) {
            this.finished = true;
          }
           //this.page++;
           this.isEmpty = this.goods.length == 0;
        }
        else{
          Toast(res.data.msg)
          if (this.refreshing) {
            this.goods = [];
            this.refreshing = false;
          }
          this.error = true;
          this.loading = false;
        }
      });
     },
    getShopList(type)
    {
      //type:收藏了类型 1 商品 2 商铺
        let params ={'type':type,'page':this.page1,'size':this.pageSize1}
        getObjList(params).then((res) => {
        if (res.data.code === 0) {
           
          this.total1 = res.data.data.total;
          if (this.refreshing) {
            this.shops = [];
            this.refreshing = false;
          }
          this.shops = this.shops.concat(res.data.data.records);
          this.loading = false;
          this.shops.forEach(m => {
          this.$set(m, 'isChecked', false);
           });
          //alert(JSON.stringify( this.shops))
         if (res.data.data.records == null || res.data.data.records.length === 0) {
          // 加载结束
           this.finished = true;
           return;
         }
          if (this.shops.length >= this.total1) {
            this.finished = true;
          }

          this.isEmpty = this.shops.length == 0;
        }
        else{
          Toast(res.data.msg)
          if (this.refreshing) {
            this.shops = [];
            this.refreshing = false;
          }
          this.error = true;
          this.loading = false;
        }
      });
     },
   //选中删除
    OnClick(item,index)
    {
     
      //console.log(JSON.stringify(this.checkedGoodsid))
      //console.log(item.id)
      if (this.checkedGoodsid.indexOf(item.id) > -1) {
         // this.selectedData[index]=null;
          //console.log(this.goods[index].isChecked)
          var a = this.checkedGoodsid.indexOf(item.id);
          //item.isChecked=false
          if(this.type==1)
          {
             this.goods[index].isChecked=false
          }else{
            this.shops[index].isChecked=false
          }
          this.checkedGoodsid.splice(a, 1);
      } else {
         this.checkedGoodsid.push(item.id); 
           if(this.type==1)
          {
          this.goods[index].isChecked=true
          }else{
            this.shops[index].isChecked=true
          }
      }
      if (this.checkedGoodsid.length < this.goods.length) {
     
        this.AllChecked = false;   // alert(this.AllChecked+"2")
      } else {
        this.AllChecked = true;   //alert(this.AllChecked+"5")
      }
       //alert(JSON.stringify(item.isChecked)+","+JSON.stringify(this.checkedGoodsid))
    },

   deleteGoods()
   {
     if(this.type==1)
     {
       if(this.checkedGoodsid.length<1)
     {
        Dialog.confirm({
        title: "提示",
        message: "请选择你要删除的项目"
      })
       return;
     }
     Dialog.confirm({
        title: "提示",
        message: "是否确定删除选择项？"
      }).then(async () => {
          const res = await  deleteObj(this.checkedGoodsid);   //调用删除接口
          if (res.data.code===0) {
            //location. reload()
           Toast('删除成功');
           let arr = [];
       
           this.goods.forEach((e) => {
           this.checkedGoodsid.indexOf(e.id)<0 ? arr.push(e) :0 ;
        });
          this.goods = arr;
          this.selectedData=[];
          this.checkedGoodsid=[];
          }
        })
        .catch(() => {
           //Toast('系统错误');
        });
     }else{
      // alert('1')
       this.deleteShops()
     }
      this.AllChecked = false;
   },
      deleteShops()
   {
     if(this.checkedGoodsid.length<1)
     {
        Dialog.confirm({
        title: "提示",
        message: "请选择你要删除的项目"
      })
       return;
     }
     Dialog.confirm({
        title: "提示",
        message: "是否确定删除选择项？"
      }).then(async () => {
          const res = await  deleteObj(this.checkedGoodsid);   //调用删除接口
          if (res.data.code===0) {
            //location. reload()
           Toast('删除成功');
           let arr = [];
       
           this.shops.forEach((e) => {
           this.checkedGoodsid.indexOf(e.id)<0 ? arr.push(e) :0 ;
        });
          this.shops = arr;
          this.selectedData=[];
          this.checkedGoodsid=[];
          }
        })
        .catch(() => {
           //Toast('系统错误');
        });
   },
    // 全选和反选
    checkAll() {
       let list = this.type==1?this.goods:this.shops;
       //console.log(JSON.stringify(list.length))
       if (this.AllChecked) {
         list.forEach(element=> {
           element.isChecked=true
         /* if (this.selectedData.indexOf(element.relationId) < 0) {
            this.selectedData.push(element.relationId);
          }*/
           if (this.checkedGoodsid.indexOf(element.id) < 0) {
            this.checkedGoodsid.push(element.id);
          }

        });
        //console.log(JSON.stringify(this.checkedGoodsid))
      } else {
         list.forEach(element=> {
           element.isChecked=false
        });
        //this.selectedData = [];
        this.checkedGoodsid = [];
      }
    },
      // 返回原生
    Close()
    {

     let u = navigator.userAgent
     let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
     let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
     if(isIOS)
     {
          window.webkit.messageHandlers.close.postMessage('up');   
     }
     if (isAndroid) {
       window.android.close(); 
      } 
       this.$store.commit('setCollectType', 0)
      },
      openShop(item,index)
      {
         //alert(this.$store.state.session)
         if(!this.isShow)
         {
          if(this.$store.state.session!=null&&this.$store.state.session!='')
         {
          this.$router.push({path:'/detailsPage',query: {'Id':item.shopInfo.id,'WoSession':this.$store.state.session}})
         }else{
          this.$router.push({path:'/detailsPage',query: {'Id':item.shopInfo.id}})
          //@click="$router.push({path:'/detailsPage',query: {id: drivings.shopId}})"
        }
       }else{
          this.OnClick(item,index)
        }
      },
      openGoods(item,index)
      {
        if(!this.isShow)
        {
            //alert(this.$store.state.session)$router.push({name:'Goodsdetails',query: {'Id':item.relationId,'platformId':item.platformId,'type':'0'}})
        if(this.$store.state.session!=null&&this.$store.state.session!='')
        {
          let params={'Id':item.relationId, 'session':this.$store.state.session,'platformId':item.platformId,'type':'0'}
          if(this.$store.state.token!=null&&this.$store.state.token!='')
          {  
             params={'Id':item.relationId, 'session':this.$store.state.session,'platformId':item.platformId,'type':'0','token':this.$store.state.token}
             if(this.$store.state.empower!=null&&this.$store.state.empower!='')
             {
                  params={'Id':item.relationId, 'session':this.$store.state.session,'platformId':item.platformId,'type':'0','token':this.$store.state.token,'empower':this.$store.state.empower}
             }
          }
          this.$router.push({name:'Goodsdetails',query: params})
        }else{
         this.$router.push({name:'Goodsdetails',query: {'Id':item.relationId,'platformId':item.platformId,'type':'0'}})
        }
        }else{
          this.OnClick(item,index)
        }
        
      }
  }
};
</script>

<style lang="less" scoped>
.content1-collect
{
  position: relative;
padding-top: 50px;
}
   .info-collect {
  overflow-wrap: break-word;
  color: rgb(204, 197, 197);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
}
.word10-collect {
  margin-left: 5px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 10px;
  text-align: center;


}
.word4-collect {
  overflow-wrap: break-word;
  color: rgba(168, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 1px;
}
.word-collect {
  left: 11px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
}

.container-collect {
      position: relative;
      width: 100px;
      height: 100px;
      float: left;
      margin-left: 10px;
    }
.divmain-collect
{
  width:100%;
  overflow-x:scroll;
  display:flex;
  flex-wrap:no-wrap;
  padding-top: 20px;
}
  .main1-collect{
  margin-left: 2%;
  margin-right: 2%;
  overflow:hidden;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  justify-content: center;
  width:96%;
  margin-bottom: 10px;
  margin-top: 10px;
  }

.span1-collect
{
    background: url(/img/youhui-1.png) 100% no-repeat;
    width: 46px;
    height: 18px;
    background-size: cover;
    background-position: center;
    display: inline-block;
}
.van-card__thumb {
    position: relative;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    width: 133px;
    height: 133px;
    margin-right: 8px;
}
.check-collect
{
  display: none;
}
.check-collect1
{
  display: contents;
  margin-top:60px;
  margin-left:10px;

}
.check-submit1
{
  display: none;
}
.check-submit
{
  display: contents;
}
.van-checkbox__icon 
{
  padding-top:50px
}
.goods-p1{
//text-align: center;
//height:34px;
font-size:17px;margin-left:5px;
text-overflow: -o-ellipsis-lastline;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.wrap {
  margin-top: 50px;
  /* margin-bottom: 100px; */

}
.item {
  display: flex;
  background-color: #ffffff;
  border-bottom: 1px solid #d9d6d9;
  margin-left: 2%;
  margin-right: 2%;
  overflow:hidden;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  justify-content: center;
  width:96%;
  margin-top:10px;
  margin-bottom:10px;
}
.van-card {
  flex: 1;
  background-color: #ffffff;
}
.div-left
{

  border-radius: 50rem;
  background-color: rgba(241, 241, 241, 1);
  width: 76px;
  justify-content: center;
  align-items: flex-end;float:left;font-size:14px;
  color: rgba(51, 51, 51, 1);
  text-align: center;

}
.div-right
{

  border-radius: 50rem;
  background-color: rgba(241, 241, 241, 1);
  width: 76px;
  justify-content: center;
  align-items: flex-end;float:right;font-size:14px;
  color: rgba(51, 51, 51, 1);
  text-align: center;

}
.div-leftafter
{

  border-radius: 50rem;
  background: -webkit-linear-gradient(left, #FF7200FF, #FF3C00FF);
  width: 76px;
  justify-content: center;
  align-items: flex-end;float:left;font-size:14px;
  color: white;
  text-align: center;

}
.div-rightafter
{
 
  border-radius: 50rem;
  background: -webkit-linear-gradient(left, #FF7200FF, #FF3C00FF);
  width: 76px;
  justify-content: center;
  align-items: flex-end;float:right;font-size:14px;
  color: white;
  text-align: center;
}
.word19-collect
{
    height:24px;
    font-size: 10px;
    color: rgb(34, 33, 33);
    background-color: rgba(241, 241, 241, 1);
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.txt7-collect
{
    font-size: 16px;
    color: rgb(34, 33, 33);
    margin-top: 10px;
}
</style>
